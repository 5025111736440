import React from "react"
import { getNewestDate, getVariables, generatePath, MAX_COMBINATIONS } from "../lib/utils"
import Layout from "../components/Layout"
import CompareBody from "../components/CompareBody"
import PageTitle from "../components/PageTitle"
import CompareHeader from "../components/CompareHeader"
import CompareSidebarSearch from "../components/CompareSidebarSearch"
import Seo from "../components/Seo/Seo"
import { graphql } from "gatsby"

export const query = graphql`
    query ($slug: [String!]) {
        datoCmsLocation(websiteUrl: {eq: "mx.mattress.zone"}) {
            compareMetaTitleTemplate
            compareMetaDescriptionTemplate
            compareProductSeparator
            compareTitleAfterText
            compareTitleBeforeText
            compareSubtitleAfterText
            compareSubtitleBeforeText
            currencySymbol
            dimensionsSymbol
            weightSymbol
        }
        allDatoCmsMattress(
            filter: {
                locale: {eq: "es"}, 
                slug: {in: $slug}, 
                location: {
                    websiteUrl: {eq: "mx.mattress.zone"}
                    }
                },
                sort: {
                    fields: slug, 
                    order: ASC
                }
            ) {
            nodes {
                mattressImage {
                    gatsbyImageData(
                        imgixParams: {auto: "format", fit: "crop", h: "90", w: "180", colors: 10, mask: "corners", cornerRadius: "10,10,10,10"}
                        placeholder: BLURRED
                    )
                }
                ...FragmentMattressFields
            }
        }
        datoCmsTranslation(locale: {eq: "es"}) {
            ...FragmentTranslationFields
        }
    }`

const Compare = ({ data, pageContext }) => {
    const variables = getVariables()
    let title_vs = data.datoCmsLocation.compareProductSeparator
    let title_before = data.datoCmsLocation.compareTitleBeforeText
    let subtitle_after = data.datoCmsLocation.compareSubtitleAfterText
    let subtitle_before = data.datoCmsLocation.compareSubtitleBeforeText

    let mattress_title = []

    let allDates = []
    let all_compare_mattresses_sizes = []
    data.allDatoCmsMattress.nodes.map((mattress, i) => {
        mattress_title.push(mattress.name)
        allDates.push(mattress.meta.updatedAt)

        //need to push the brand updated at date too
        allDates.push(mattress.brand.meta.updatedAt)

        let buttons = mattress.buttons
        for (let i = 0; i < buttons.length; i++) {
            let is_repeated_size = all_compare_mattresses_sizes.indexOf(buttons[i].size.sizeName)
            if (is_repeated_size === -1) {
                //not found so lets add the size
                all_compare_mattresses_sizes.push(buttons[i].size.sizeName)
            }
        }
        return null
    })

    let all_mattresses_title = mattress_title.join(' ' + title_vs + ' ')

    let reverse_mattress_title = mattress_title.reverse()
    let reverse_all_mattress_title = reverse_mattress_title.join(' ' + title_vs + ' ')

    const newestDate = getNewestDate(allDates)

    let pageTitle = title_before + ' ' + all_mattresses_title
    let pageSubtitle = `${subtitle_before} ${reverse_all_mattress_title} ${data.datoCmsTranslation.mattresses} ${subtitle_after}`

    let metaTitle = data.datoCmsLocation.compareMetaTitleTemplate
    let meta_description = data.datoCmsLocation.compareMetaDescriptionTemplate
    let meta_image = null
    let meta_slug = generatePath(
        pageContext.slug,
        'es',
        variables.comparer,
        variables.mattress
    )

    metaTitle = metaTitle.replace('%%pagetitle%%', all_mattresses_title)
    metaTitle = metaTitle.replace('%%currentyear%%', newestDate.year)
    meta_description = meta_description.replace('%%pagetitle%%', all_mattresses_title)

    return (
        <Layout>
            <Seo
                title={metaTitle}
                description={meta_description}
                image={meta_image}
                pathname={meta_slug}
                article
            />

            <div className="bg-zone-1">
                <div className="container mx-auto flex flex-col lg:flex-row justify-between">
                    <main className={`${pageContext.slug.length > 2 ? 'w-full' : 'w-full lg:w-3/4 lg:pr-8'}`}>
                        <PageTitle
                            newestDate={newestDate}
                            title={pageTitle}
                            subTitle={pageSubtitle}
                            link={null}
                            category={variables.comparison} />

                        <CompareHeader mattresses={data.allDatoCmsMattress.nodes} rawSlugs={pageContext.slug} />

                        <CompareBody mattresses={data.allDatoCmsMattress.nodes} translations={data.datoCmsTranslation} location={data.datoCmsLocation} sizes={all_compare_mattresses_sizes} />

                    </main>
                    {pageContext.slug.length < MAX_COMBINATIONS && (
                        <CompareSidebarSearch rawSlugs={pageContext.slug} className={`px-2 md:px-4 xl:px-0 w-full lg:w-1/4`} />
                    )}
                </div>
            </div>
        </Layout>
    )
}

export default Compare

